// import { Enum, Assignable } from "./utils/enums";
// import {
//   AccessKey,
//   AccessKeyPermission,
//   Action,
//   AddKey,
//   CreateAccount,
//   DeleteAccount,
//   DeleteKey,
//   DeployContract,
//   FullAccessPermission,
//   FunctionCall,
//   FunctionCallPermission,
//   Signature,
//   SignedTransaction,
//   Stake,
//   Transaction,
//   Transfer,
// } from "near-api-js/lib/transaction";
import { transactions, utils } from "near-api-js";
// import { PublicKey } from "near-api-js/lib/utils";

// export class IAction extends Assignable {}

export const SCHEMA = new Map<Function, any>([
  [
    transactions.Signature,
    {
      kind: "struct",
      fields: [
        ["keyType", "u8"],
        ["data", [64]],
      ],
    },
  ],
  [
    transactions.SignedTransaction,
    {
      kind: "struct",
      fields: [
        ["transaction", transactions.Transaction],
        ["signature", transactions.Signature],
      ],
    },
  ],
  [
    transactions.Transaction,
    {
      kind: "struct",
      fields: [
        ["signerId", "string"],
        ["publicKey", utils.PublicKey],
        ["nonce", "u64"],
        ["receiverId", "string"],
        ["blockHash", [32]],
        ["actions", [transactions.Action]],
      ],
    },
  ],
  [
    utils.PublicKey,
    {
      kind: "struct",
      fields: [
        ["keyType", "u8"],
        ["data", [32]],
      ],
    },
  ],
  [
    transactions.AccessKey,
    {
      kind: "struct",
      fields: [
        ["nonce", "u64"],
        ["permission", transactions.AccessKeyPermission],
      ],
    },
  ],
  [
    transactions.AccessKeyPermission,
    {
      kind: "enum",
      field: "enum",
      values: [
        ["functionCall", transactions.FunctionCallPermission],
        ["fullAccess", transactions.FullAccessPermission],
      ],
    },
  ],
  [
    transactions.FunctionCallPermission,
    {
      kind: "struct",
      fields: [
        ["allowance", { kind: "option", type: "u128" }],
        ["receiverId", "string"],
        ["methodNames", ["string"]],
      ],
    },
  ],
  [transactions.FullAccessPermission, { kind: "struct", fields: [] }],
  [
    transactions.Action,
    {
      kind: "enum",
      field: "enum",
      values: [
        ["createAccount", transactions.CreateAccount],
        ["deployContract", transactions.DeployContract],
        ["functionCall", transactions.FunctionCall],
        ["transfer", transactions.Transfer],
        ["stake", transactions.Stake],
        ["addKey", transactions.AddKey],
        ["deleteKey", transactions.DeleteKey],
        ["deleteAccount", transactions.DeleteAccount],
      ],
    },
  ],
  [transactions.CreateAccount, { kind: "struct", fields: [] }],
  [
    transactions.DeployContract,
    {
      kind: "struct",
      fields: [["code", ["u8"]]],
    },
  ],
  [
    transactions.FunctionCall,
    {
      kind: "struct",
      fields: [
        ["methodName", "string"],
        ["args", ["u8"]],
        ["gas", "u64"],
        ["deposit", "u128"],
      ],
    },
  ],
  [
    transactions.Transfer,
    {
      kind: "struct",
      fields: [["deposit", "u128"]],
    },
  ],
  [
    transactions.Stake,
    {
      kind: "struct",
      fields: [
        ["stake", "u128"],
        ["publicKey", utils.PublicKey],
      ],
    },
  ],
  [
    transactions.AddKey,
    {
      kind: "struct",
      fields: [
        ["publicKey", utils.PublicKey],
        ["accessKey", transactions.AccessKey],
      ],
    },
  ],
  [
    transactions.DeleteKey,
    {
      kind: "struct",
      fields: [["publicKey", utils.PublicKey]],
    },
  ],
  [
    transactions.DeleteAccount,
    {
      kind: "struct",
      fields: [["beneficiaryId", "string"]],
    },
  ],
]);
