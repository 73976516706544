import { Buffer } from "buffer";
// import { envConfig } from "../../meteor-sdk/src/envConfig";
//
// envConfig.wallet_base_url = (import.meta as any).env.VITE_WALLET_BASE_URL ?? envConfig.wallet_base_url;

// @ts-ignore
globalThis.Buffer = Buffer;

if (typeof (window as any).global === "undefined") {
  (window as any).global = window;
}
